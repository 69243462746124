import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.047 13.435h.092a3.736 3.736 0 0 0 3.62-3.743 3.76 3.76 0 0 0-3.75-3.753A3.76 3.76 0 0 0 8.26 9.692c0 2.03 1.582 3.678 3.694 3.743zm-2.4-3.753A2.357 2.357 0 0 1 12 7.326a2.357 2.357 0 0 1 .12 4.712.8.8 0 0 0-.213 0 2.345 2.345 0 0 1-2.26-2.356M5.256 19.395A9.96 9.96 0 0 0 12 22.02a9.96 9.96 0 0 0 6.744-2.626.67.67 0 0 0 .223-.587c-.12-1.108-.809-2.132-1.953-2.905-2.763-1.844-7.256-1.844-10.028 0-1.144.763-1.833 1.797-1.953 2.905a.7.7 0 0 0 .223.587M12 20.624a8.56 8.56 0 0 1-5.507-2.002c.167-.568.605-1.117 1.265-1.564 2.307-1.528 6.186-1.528 8.475 0 .66.447 1.107.996 1.274 1.564A8.56 8.56 0 0 1 12 20.624",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2 12.01c0 5.522 4.484 10.01 10 10.01s10-4.488 10-10.01S17.516 2 12 2 2 6.488 2 12.01m1.395 0c0-4.749 3.86-8.613 8.605-8.613 4.744 0 8.605 3.864 8.605 8.613 0 4.75-3.86 8.614-8.605 8.614-4.744 0-8.605-3.865-8.605-8.614",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }